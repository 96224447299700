import { setGlobal, addReducer } from "reactn";
import { startOfWeek, endOfWeek, format } from "date-fns";
import axios from "axios";
import { getTodayDateInLocalTZ, convertToDateOnlyISOString } from "../../lib/util";


const today = new Date();
const weeklySlots = {
  slots: 0,
  start: startOfWeek(today),
  end: endOfWeek(today),
  myChildren: 0,
  days: {},
  available: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0
  }
};

setGlobal({ weeklySlots: weeklySlots });

const availRoutes = [];
const profile = {
  id: null,
  organization_title: "",
  dashboard_title: "",
  address1: "",
  address2: "",
  city: "",
  province: "",
  postalcode: "",
  country: "",
  target_ages: [],
  description: "",
  main_lat: null,
  main_long: null,
  program_types: [],
  website_url: "",
  days_of_week: [],
  gallery: [],
  facebook: "",
  instagram: "",
  stripeId: null,
  manual_payments: false,
  other_locations: [],
  theme: 0,
  order_suffix: "",
  order_prefix: "",
  google_token: null,
  google_mail: "",
  bcg_image: "partners/Banners/Banner1.jpg",
  classification: null,
  format: "",
  marketplace_image: null,
  profile_completion_validation: null,
  stripeTaxId: [],
  timezone: "",
  idempotentKeyStripe: null
};
const giftTemplate = {
  id: null,
  amount: null,
  gift_name: "",
  image: 1,
  description: ""
};
const eventTemplate = {
  id: null,
  event_title: "",
  address1: "",
  address2: "",
  city: "",
  province: "",
  postalcode: "",
  event_start: null,
  event_end: null,
  target_ages: [1, 2],
  description: "",
  event_image: "partners/Banners/Banner1.jpg",
  all_ages: false,
  tickets: [],
  formId: null,
  application_required: false,
  addChild: false,
  manual_invoices: false
};
const membershipTemplate = {
  id: null,
  membership_name: "",
  membership_location: "",
  membership_type: [],
  max_amount: null,
  membership_image: 0,
  membership_image_url: null,
  description: "",
  additional_perks: "",
  auto_renew: false,
  published: false,
  prices: [],
  formId: null,
  application_required: false,
  manual_invoices: false,
  isEftDisabled: false
};

const partyTemplate = _ => ({
  id: null,
  party_title: "",
  address1: "",
  address2: "",
  city: "",
  province: "",
  postalcode: "",
  party_start: null,
  party_end: null,
  target_ages: [1, 2],
  description: "",
  party_image: "partners/Banners/Banner1.jpg",
  all_ages: false,
  tickets: [],
  formId: null,
  application_required: false,
  addChild: false,
  manual_invoices: false
});

//This was previously a constant object, and everytime the resetEvent reducer was called,
//it would not refresh the "program_start" date. Making it a function now refreshes the date.
const programTemplate = _ => ({
  id: null,
  program_category: "",
  program_name: "",
  age_groupings: [],
  age_range: [1, 2],
  all_ages: false,
  description: "",
  additional_notes: "",
  program_photo: `partners/Banners/Banner1.jpg`,
  address1: "",
  address2: "",
  city: "",
  province: "",
  show_location: false,
  postal_code: "",
  program_details: "",
  country: "",
  category_description: "",
  total_spots: 1,
  show_total_spots: false,
  enable_waitlist: false,
  days_of_week: {},
  program_start: convertToDateOnlyISOString(getTodayDateInLocalTZ()),   //Just today's date, local timezone, and zero-hour!
  number_of_weeks: 0,
  timing: [-1, -1],
  closure_dates: [],
  closure_timeframe: [null, null],
  formId: null,
  members_only_program: false,
  open_registration: false,
  attendance_roaster: false,
  check_in_roaster: false,
  program_milestones: false,
  rooms: false,
  transaction_cost: false,
  manual_invoices: false,
  min_cancellation: null,
  plans: [],
  addChild: false,
  application_required: false,
  auto_deposit: false,
  isRecurring: false,
  isEftDisabled: false,
  enableCustomTimes: false,
  customTimes: {}
});

const programOnlineTemplate = _ => ({
  // tech links section
  camera_on_start: true,
  microphones_on_start: true,
  enable_recording: null,
  only_broadcast: null,
  enable_google_meets: null,
  maximum_participants: null,
  enable_chat: true,
  enable_people_ui: true,
  enable_knocking: null,

  id: null,
  program_category: "",
  program_name: "",
  age_groupings: [],
  age_range: [1, 0],
  all_ages: false,
  description: "",
  additional_notes: "",
  program_photo: `partners/Banners/Banner1.jpg`,
  address1: "",
  address2: "",
  unlimited_spots: false,
  city: "",
  province: "",
  show_location: false,
  postal_code: "",
  program_details: "",
  country: "",
  category_description: "",
  total_spots: 1,
  show_total_spots: false,
  enable_waitlist: false,
  days_of_week: {},
  program_start: convertToDateOnlyISOString(getTodayDateInLocalTZ()),   //Just today's date, local timezone, and zero-hour!
  number_of_weeks: 0,
  timing: [-1, -1],
  closure_dates: [],
  closure_timeframe: [null, null],
  formId: null,
  members_only_program: false,
  open_registration: false,
  attendance_roaster: false,
  check_in_roaster: false,
  program_milestones: false,
  transaction_cost: false,
  manual_invoices: false,
  min_cancellation: null,
  plans: [],
  application_required: false,
  timezone_offset: "-----------------",
  isRecurring: false,
  isEftDisabled: false
});

const News_Updates = {
  id: null,
  title: "",
  mainbody: "",
  bottomsection: "",
  listing_image: "",
  date: null,
  headerimage: "",
  middlegallery: [],
  bottomgallery: [],
  additional_text: "",
  additional_gallery: []
};

// User's Permission
// const permissions = null

setGlobal({
  organizationInfo: profile,
  event: eventTemplate,
  membership: membershipTemplate,
  program: programTemplate(),
  news_and_updates: News_Updates,
  gift_card: giftTemplate,
  online: programOnlineTemplate(),
  party: partyTemplate(),
  // permissions: permissions
});

// addReducer('get_permissions', async (global, dispatch, submit) => {
//   if (permissions) {
//     return global
//   }
//   setGlobal({ loading: true })
//   const ep = `${process.env.REACT_APP_API}/partner/permissions/all`
//   const resp = await axios.get(ep)
//   if (resp.data && resp.data.success) {
//     setGlobal({ loading: false })
//     const permissions = resp.data.permissions
//     console.log('got the permissions', permissions)
//     return {
//       ...global,
//       permissions
//     }
//   }

//   setGlobal({ loading: false })
//   return global

// })

addReducer("resetEvent", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  return {
    ...global,
    event: eventTemplate,
    membership: membershipTemplate,
    program: programTemplate(),
    party: partyTemplate(),
    news_and_updates: News_Updates,
    gift_card: giftTemplate,
    online: programOnlineTemplate(),
  };
});
addReducer("profile_reset", async (global, dispatch, submit) => {
  return { ...global, organizationInfo: profile };
});
addReducer("saveMembership", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const validMemberships = global.membership.prices.filter(plan => {
      let ret = false;
      let active, archived;
      try {
        const p = JSON.parse(plan);
        active = p.active;
        archived = p.archived;
      } catch (err) {
        active = plan.active;
        archived = plan.archived;
      }
      if ((active === undefined || active) && !archived) ret = true;
      return ret;
    }).length > 0

    console.log("validMemberships", validMemberships);
    if (!validMemberships) {
      setGlobal({
        membership: {
          ...global.membership,
          published: false
        }
      });
    }

    const membership = validMemberships ? { ...global.membership } : { ...global.membership, published: false }
    for (let tick of global.membership.prices) {
      const ticket = typeof tick === "string" ? JSON.parse(tick) : tick;

      if (!ticket.price_stripe) {
        const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
        const res = await axios.post(ep, {
          mem: ticket,
          parent: "Membership",
          name: global.membership.membership_name,
          type: ticket.auto_renew ? "Recurring" : "One-time",
          product: global.membership.product_stripe
        });

        membership.prices = membership.prices
          .map(e => (typeof e === "string" ? JSON.parse(e) : e))
          .map(e =>
            e.id === ticket.id ? { ...e, price_stripe: res.data.data.id } : e
          );
        membership.product_stripe = res.data.data.product;
      }
    }
    const ep = `${process.env.REACT_APP_API}/partners/memberships/save`;
    const res = await axios.post(ep, {
      ...membership
    });

    return {
      ...global,
      membership: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("saveNews", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/news/save`;
    const res = await axios.post(ep, {
      ...global.news_and_updates
    });

    return {
      ...global,
      news_and_updates: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("saveGifts", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/gifts/save`;
    const res = await axios.post(ep, {
      ...global.gift_card
    });

    return {
      ...global,
      gift_card: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("getGift", async (global, dispatch, param) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/gifts/${param}`;
    const res = await axios.get(ep);
    if (!res.data.success) {
      dispatch.resetEvent();
      return;
    }

    return {
      ...global,
      gift_card: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("saveProgram", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/programs/save`;
    console.log("THIS HAPPENED", global.program);
    const res = await axios.post(ep, {
      ...global.program
    });

    return {
      ...global,
      program: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("saveVirtual", async (global, dispatch, submit) => {
  setGlobal({ loading: true });

  try {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/save`;

    const start_time = parseFloat(global.online.timing[0]);
    const end_time = parseFloat(global.online.timing[1]);
    let utc_start_time = -1;
    let utc_end_time = -1;

    if (start_time && start_time !== -1) {
      utc_start_time = start_time;
    }

    if (end_time && end_time !== -1) {
      utc_end_time = end_time;
    }

    let utc_timing = [utc_start_time, utc_end_time];

    let session_timings;
    if (global.online.program_category === "Single Sessions") {
      utc_timing = global.online.timing.map(e => parseFloat(e));
      session_timings = global.online.timing.map(e => parseFloat(e));
    }

    console.log("utc_timing", utc_timing);

    const res = await axios.post(ep, {
      ...global.online,
      timing: utc_timing,
    });

    const data = res.data.data;
    if (global.online.program_category !== "Single Sessions") {
      data.timing = [start_time, end_time];
    } else {
      data.timing = session_timings;
    }

    console.log("data", data);

    return {
      ...global,
      online: { ...data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("saveEvent", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const event = { ...global.event };
    console.log("GLOBAL EVENT: ", event);
    for (let tick of global.event.tickets) {
      const ticket = typeof tick === "string" ? JSON.parse(tick) : tick;
      if (!ticket.ticket_stripe && ticket.ticket_status !== "Free") {
        const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
        const res = await axios.post(ep, {
          ticket: ticket,
          parent: "Event",
          name: global.event.event_title,
          type: "One-Time",
          product: global.event.product_stripe
        });
        event.tickets = event.tickets
          .map(e => (typeof e === "string" ? JSON.parse(e) : e))
          .map(e =>
            e.id === ticket.id ? { ...e, ticket_stripe: res.data.data.id } : e
          );
        event.product_stripe = res.data.data.product;
      }
    }
    const ep = `${process.env.REACT_APP_API}/partners/events/save`;
    const res = await axios.post(ep, {
      ...event
    });

    return {
      ...global,
      event: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("saveParty", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const party = { ...global.party };
    for (let tick of global.party.tickets) {
      const ticket = typeof tick === "string" ? JSON.parse(tick) : tick;
      if (!ticket.ticket_stripe && ticket.ticket_status !== "Free") {
        const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
        const res = await axios.post(ep, {
          ticket: ticket,
          parent: "Party",
          name: global.party.party_title,
          type: "One-Time",
          product: global.party.product_stripe
        });
        party.tickets = party.tickets
          .map(e => (typeof e === "string" ? JSON.parse(e) : e))
          .map(e =>
            e.id === ticket.id ? { ...e, ticket_stripe: res.data.data.id } : e
          );
        party.product_stripe = res.data.data.product;
      }
    }
    const ep = `${process.env.REACT_APP_API}/partners/parties/save`;
    const res = await axios.post(ep, {
      ...party
    });

    return {
      ...global,
      party: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("getNews", async (global, dispatch, param) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/news/${param}`;
    const res = await axios.get(ep);
    if (!res.data.success) {
      dispatch.resetEvent();
      return;
    }

    return {
      ...global,
      news_and_updates: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("getEvent", async (global, dispatch, param) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/events/${param}`;
    const res = await axios.get(ep);
    if (!res.data.success) {
      dispatch.resetEvent();
      return;
    }

    return {
      ...global,
      event: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("getMembership", async (global, dispatch, param) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/${param}`;
    const res = await axios.get(ep);
    if (!res.data.success) {
      dispatch.resetEvent();
      return;
    }

    return {
      ...global,
      membership: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("getProgram", async (global, dispatch, param) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/programs/${param}`;
    const ep2 = `${process.env.REACT_APP_API}/partners/programs/customers/${param}`;
    const [res, res2] = await Promise.all([axios.get(ep), axios.get(ep2)]);
    if (!res.data.success) {
      dispatch.resetEvent();
      return;
    }

    return {
      ...global,
      program: { ...res.data.data },
      enrollments: res2.data.data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("getVirtual", async (global, dispatch, param) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/${param}`;
    const ep2 = `${process.env.REACT_APP_API}/partners/virtual/customers/${param}`;
    const [res, res2] = await Promise.all([axios.get(ep), axios.get(ep2)]);

    if (!res.data.success) {
      dispatch.resetEvent();
      return;
    }
    const data = res.data.data;
    const timing = data.timing;

    const start_time = parseFloat(timing[0]);
    const end_time = parseFloat(timing[1]);
    let local_start_time = -1;
    let local_end_time = -1;

    if (start_time && start_time !== -1) {
      local_start_time = start_time;
    }
    if (end_time && end_time !== -1) {
      local_end_time = end_time;
    }
    let local_timing = [local_start_time, local_end_time];

    if (data.program_category === "Single Sessions") {
      local_timing = data.timing.map(e => parseFloat(e));
    }

    data.timing = local_timing;

    return {
      ...global,
      online: { ...data },
      enrollments: res2.data.data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("getParty", async (global, dispatch, param) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/parties/${param}`;
    const res = await axios.get(ep);
    if (!res.data.success) {
      dispatch.resetEvent();
      return;
    }

    return {
      ...global,
      party: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("saveOrganizationalProfile", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const global_edited = global;
    // Set all other locations without a visible attribute to true. Can be changed in the additional details page.
    global_edited.organizationInfo.other_locations.forEach(e => {
      if (e.visible === undefined) {
        e.visible = true;
      }
    })
    const ep = `${process.env.REACT_APP_API}/partners/organization_profile/save`;
    const res = await axios.post(ep, {
      ...global_edited.organizationInfo
    });

    // Initializing credit card processing fee rate
    if (
      global.organizationInfo.stripeId &&
      !global.organizationInfo.creditCardFeeId
    ) {
      const ep2 = `${process.env.REACT_APP_API}/partners/stripe/addCreditCardFee`;

      const res2 = await axios.post(ep2, {
        display_name: "Credit Card Fee",
        description: "Credit Card Processing Fee",
        percentage: 1.5
      });

      const ep3 = `${process.env.REACT_APP_API}/partners/stripe/offlinesub-coupon`;

      const res3 =  await axios.post(ep3, {
        percent_off: 100,
        duration: 'once',
        name: "OFFLINESUB"
      });

      return {
        ...global_edited,
        organizationInfo: { ...res2.data.data },
        loading: false,
        lastAPICall: res
      };
    }

    // Initializing general credit card processing fee rate for regular 3%
    if (
      global.organizationInfo.stripeId &&
      !global.organizationInfo.regularCreditCardFeeId
    ) {
      const ep2 = `${process.env.REACT_APP_API}/partners/stripe/addRegularCreditCardFee`;

      const res2 = await axios.post(ep2, {
        display_name: "Credit Card Fee",
        description: "Credit Card Processing Fee",
        percentage: 3
      });

      return {
        ...global_edited,
        organizationInfo: { ...res2.data.data },
        loading: false,
        lastAPICall: res
      };
    }

    return {
      ...global_edited,
      organizationInfo: { ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("uploadProfilePhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });

  try {
    const ep = `${process.env.REACT_APP_API}/partners/users/photo`;
    const res = await axios.post(ep, photo);

    return {
      ...global,
      dsUser: { ...global.dsUser, photo: res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("uploadProgramPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });

  try {
    const ep = `${process.env.REACT_APP_API}/partners/organization_profile/photo`;
    const res = await axios.post(ep, photo);

    // if(res.data.data.success){
    //   toast.success(`Image Uploaded Successfully`);
    // }
    if (res.data.data["gallery"]) {
      return {
        ...global,
        organizationInfo: {
          ...global.organizationInfo,
          gallery: [...global.organizationInfo.gallery, res.data.data.gallery]
        },
        loading: false,
        lastAPICall: res
      };
    }
    return {
      ...global,
      organizationInfo: { ...global.organizationInfo, ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("uploadProgramWelcomePagePhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/welcome/photo`;
    const res = await axios.post(ep, photo);

    return {
      ...global,
      program: { ...global.program, ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("uploadSPRPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/programs/photo`;
    const res = await axios.post(ep, photo);
    return {
      ...global,
      program: { ...global.program, ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("uploadVRPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/photo`;
    const res = await axios.post(ep, photo);
    return {
      ...global,
      online: { ...global.online, ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("uploadMembershipPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/photo`;
    const res = await axios.post(ep, photo);
    return {
      ...global,
      membership: { ...global.membership, ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("deleteNewsPhoto", async (global, dispatch, key) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/news/photo`;
    const res = await axios.delete(ep, { key });
    return {
      ...global,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("uploadEventPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/events/photo`;
    const res = await axios.post(ep, photo);
    return {
      ...global,
      event: { ...global.event, ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("uploadNewsPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/news/photo`;
    const res = await axios.post(ep, photo);
    return {
      ...global,
      news_and_updates: {
        ...global.news_and_updates,
        [res.data.data.location]:
          typeof global.news_and_updates[res.data.data.location] === "string"
            ? res.data.data.file
            : [
                ...global.news_and_updates[res.data.data.location],
                res.data.data.file
              ]
      },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("uploadPartyPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/parties/photo`;
    const res = await axios.post(ep, photo);
    return {
      ...global,
      party: { ...global.party, ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

const child = {
  id: "0",
  fullName: "",
  birthDate: "",
  gender: "",
  languages: [],
  allergies: [],
  immunized: "",
  medication: [],
  specialFood: [],
  pottyTrained: "",
  healthConcerns: [],
  likes: [],
  dislikes: [],
  comments: ""
};
const parent = {
  displayName: "",
  email: "",
  planId: 0,
  children: [],
  emergency: [],
  guardians: [],
  physicians: []
};
addReducer("addParentProfile", (global, dispatch, obj) => ({
  pendingParent: obj
}));
addReducer("addChildToFamilyProfile", (global, dispatch) => ({
  pendingParent: {
    ...global.pendingParent,
    children: [
      ...global.pendingParent.children,
      ...[{ ...child, id: global.pendingParent.children.length.toString() }]
    ]
  }
}));
addReducer("removeChildFromFamilyProfile", (global, dispatch, obj) => () => {
  return {
    pendingParent: {
      ...global.pendingParent,
      children: [...global.pendingParent.children].filter(c => c.id !== obj.id)
    }
  };
});

const parentGuardian = {
  id: "0",
  fullName: "",
  address: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  email: "",
  homePhone: "",
  workPhone: ""
};

addReducer("addGuardianToFamilyProfile", (global, dispatch) => ({
  pendingParent: {
    ...global.pendingParent,
    guardians: [
      ...[
        {
          ...parentGuardian,
          id: global.pendingParent.guardians.length.toString()
        }
      ]
    ]
  }
}));
addReducer("removeGuardianFromFamilyProfile", (global, dispatch, obj) => () => {
  return {
    pendingParent: {
      ...global.pendingParent,
      guardians: [...global.pendingParent.guardians].filter(
        c => c.id !== obj.id
      )
    }
  };
});

const emergencyContact = {
  id: "0",
  children: [],
  relationship: "",
  fullName: "",
  address: "",
  phone: ""
};

addReducer("addEmergencyContactToFamilyProfile", (global, dispatch) => ({
  pendingParent: {
    ...global.pendingParent,
    emergency: [
      ...global.pendingParent.emergency,
      ...[
        {
          ...emergencyContact,
          id: global.pendingParent.emergency.length.toString()
        }
      ]
    ]
  }
}));
addReducer(
  "removeEmergencyContactFromFamilyProfile",
  (global, dispatch, obj) => () => {
    return {
      pendingParent: {
        ...global.pendingParent,
        emergency: [...global.pendingParent.emergency].filter(
          c => c.id !== obj.id
        )
      }
    };
  }
);

const physician = {
  id: "0",
  children: [],
  fullName: "",
  address: "",
  phone: "",
  company: ""
};

addReducer("addPhysicianToFamilyProfile", (global, dispatch) => ({
  pendingParent: {
    ...global.pendingParent,
    physicians: [
      ...[
        { ...physician, id: global.pendingParent.physicians.length.toString() }
      ]
    ]
  }
}));
addReducer(
  "removePhysicianFromFamilyProfile",
  (global, dispatch, obj) => () => {
    return {
      pendingParent: {
        ...global.pendingParent,
        physicians: [...global.pendingParent.physicians].filter(
          c => c.id !== obj.id
        )
      }
    };
  }
);
addReducer("createFullAccount", async (global, dispatch, success) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/partners/users/invite_parent`;
    const res = await axios.post(ep, {
      ...global.pendingParent,
      fullAccount: true
    });
    if (res.data.success) {
      setGlobal({ loading: false });
      success();
    }
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
