import React from "reactn";
import { format, addWeeks, addDays, subDays } from "date-fns";
import "../../../assets/css/componentSpecificCss/customization.css";
import { ModalBanner } from "../../../assets";
import axios from "axios";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import Dropdown from "rc-dropdown";
import { toast } from "react-toastify";
import { Switch } from "@material-ui/core";
import EditPricingPlanModal from "../../../components/modals/EditPricingPlanModal";
import EditFreeModal from "../../../components/modals/EditFreePlan";
import EditSemesterPlanModal from "../../../components/modals/EditSemesterPlanModal";
import EditSingleSessionPlanModal from "../../../components/modals/EditSingleSessionPlanModal";
import EditFreeTrialModal from "../../../components/modals/EditFreeTrialModal";
import EditDropInModal from "../../../components/modals/EditDropInPlanModal";
import PriceCardModal from "../../../components/modals/PriceCardModal";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

const getFirstDay = (startingDate, daysOfWeek) => {
  // Code to compensate for the local timezone of the starting date
  startingDate.setTime(
    startingDate.getTime() + startingDate.getTimezoneOffset() * 60 * 1000
  );

  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (!daysOfWeek) {
    return undefined;
  }
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};
class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrolledSubscribers: [],
      openEditRecurringPlanModal: false,
      openEditFreeModal: false,
      openEditSemesterPlanModal: false,
      openViewSemesterPlanModal: false,
      applicationEnrollment: [],
      allEnrollments: [],
      isDragging: false,
      openEditSingleSessionsPlanModal: false
    };

    this.innerRef = null;
    this.autoScrollInterval = null;

    this.deletePlan = this.deletePlan.bind(this);
    this.menu = this.menu.bind(this);
    this.displayEnrollments = this.displayEnrollments.bind(this);
    this.semesterMenu = this.semesterMenu.bind(this);
    this.onSemesterSelect = this.onSemesterSelect.bind(this);
    this.deleteSemesterPlan = this.deleteSemesterPlan.bind(this);
    this.handleUserDateSelectToggle =
      this.handleUserDateSelectToggle.bind(this);
  }

  onSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false ? true : false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_plan`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.prodId.id,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          program: {
            ...this.global.program,
            plans: this.global.program.plans.map((e, i) =>
              i === planKey ? { ...element, active: active } : e
            )
          }
        });
      }
      await this.dispatch.saveProgram();
    } else if (event.key === "edit") {
      this.setState({
        openEditRecurringPlanModal: true,
        selectedItem: element
      });
    } else {
      this.deletePlan(element, planKey);
    }
    // if(event.key === "delete")
    // else{
    //   const ep = `${process.env.REACT_APP_API}/partners/stripe/subscribers_plan`;
    //   this.setGlobal({ loading: true });
    //     const res = await axios.get(ep, {
    //       params: {
    //         planId: element.prodId.id,
    //         productId: element.prodId.product
    //       }
    //     });

    //     this.setGlobal({ loading: false });
    // }
  };

  onSingleSessionsSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false ? true : false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.prodId,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          program: {
            ...this.global.program,
            plans: this.global.program.plans.map((e, i) =>
              i === planKey ? { ...element, active: active } : e
            )
          }
        });
      }

      await this.dispatch.saveProgram();
    } else if (event.key === "edit") {
      this.setState({
        openEditSingleSessionsPlanModal: true,
        selectedItem: element
      });
    } else {
      this.deleteSingleSessionsPlan(element, planKey);
    }
  };

  onSemesterSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: element.prodId,
        active: active
      });

      this.setGlobal({ loading: false });
      if (res.data.success) {
        this.setGlobal({
          program: {
            ...this.global.program,
            plans: this.global.program.plans.map((e, i) =>
              i === planKey ? { ...element, active: active } : e
            )
          }
        });
      }

      if (element.dropIn_stripe) {
        const active = element.active === false;

        const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
        this.setGlobal({ loading: true });
        const res = await axios.patch(ep, {
          planId: element.dropIn_stripe,
          active: active
        });

        this.setGlobal({ loading: false });

        if (res.data.success) {
        }
      }

      await this.dispatch.saveProgram();
    } else if (event.key === "edit") {
      this.setState({
        openEditSemesterPlanModal: true,
        selectedItem: element
      });
    } else if (event.key === "view") {
      this.setState({
        openViewSemesterPlanModal: true,
        selectedItem: element
      });
    } else {
      this.deleteSemesterPlan(element, planKey);
    }
  };

  deleteSingleSessionsPlan = async (plan, key) => {
    if (plan.prodId) {
      /**
       * Validation to check for number of enrollments before deleting
       */
      // const findPlan = this.state.enrolledSubscribers.find(e => e.planId == plan.prodId.id)
      // const checkEnrollments = findPlan ? findPlan.data.length : 0
      // if(checkEnrollments > 0){
      //   toast.error('Cannot delete a program with enrollments');
      //   return
      // }else{
      const applicationEnrollment = this.state.applicationEnrollment.filter(
        f => f.plan_id === plan.id
      ).length;
      if (applicationEnrollment > 0) {
        toast.error("Cannot delete a program with applications");
        return;
      }
      const active = plan.active === false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: plan.prodId,
        active: active
      });

      this.setGlobal({ loading: false });

      if (res.data.success) {
        this.setGlobal({
          program: {
            ...this.global.program,
            plans: this.global.program.plans.map((e, i) =>
              i === key ? { ...plan, archived: true, active: active } : e
            )
          }
        });
      }

      await this.dispatch.saveProgram();
      return;
      // }
    }

    this.setGlobal({
      program: {
        ...this.global.program,
        plans: this.global.program.plans.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveProgram();
  };

  deleteSemesterPlan = async (plan, key) => {
    if (plan.prodId) {
      /**
       * Validation to check for number of enrollments before deleting
       */
      // const findPlan = this.state.enrolledSubscribers.find(e => e.planId == plan.prodId.id)
      // const checkEnrollments = findPlan ? findPlan.data.length : 0
      // if(checkEnrollments > 0){
      //   toast.error('Cannot delete a program with enrollments');
      //   return
      // }else{
      const applicationEnrollment = this.state.applicationEnrollment.filter(
        f => f.plan_id === plan.id
      ).length;
      if (applicationEnrollment > 0) {
        toast.error("Cannot delete a program with applications");
        return;
      }
      const active = plan.active === false;

      const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
      this.setGlobal({ loading: true });
      const res = await axios.patch(ep, {
        planId: plan.prodId,
        active: active
      });

      this.setGlobal({ loading: false });

      if (res.data.success) {
        this.setGlobal({
          program: {
            ...this.global.program,
            plans: this.global.program.plans.map((e, i) =>
              i === key ? { ...plan, archived: true, active: active } : e
            )
          }
        });
      }

      if (plan.dropIn_stripe) {
        const active = plan.active === false;

        const ep = `${process.env.REACT_APP_API}/partners/stripe/active_price`;
        this.setGlobal({ loading: true });
        const res = await axios.patch(ep, {
          planId: plan.dropIn_stripe,
          active: active
        });

        this.setGlobal({ loading: false });

        if (res.data.success) {
        }
      }

      await this.dispatch.saveProgram();
      return;
      // }
    }

    this.setGlobal({
      program: {
        ...this.global.program,
        plans: this.global.program.plans.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveProgram();
  };

  onFreeSelect = async (element, planKey, event) => {
    if (event.key === "inactive") {
      const active = element.active === false;

      this.setGlobal({
        program: {
          ...this.global.program,
          plans: this.global.program.plans.map((e, i) =>
            i === planKey ? { ...element, active: active } : e
          )
        }
      });

      await this.dispatch.saveProgram();
    }
    if (event.key === "edit") {
      this.setState({ openEditFreeModal: true, selectedItem: element });
    }
    if (event.key === "delete") {
      this.deleteFreePlan(element, planKey);
    }
  };

  deleteFreePlan = async (plan, key) => {
    this.setGlobal({
      program: {
        ...this.global.program,
        plans: this.global.program.plans.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveProgram();
  };

  singleSessionsMenu = (element, planKey) => {
    return (
      <Menu
        onClick={event => this.onSingleSessionsSelect(element, planKey, event)}
      >
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        {this.displaySingleSessionEnrollments(element, planKey) && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        {this.displayEnrollments(element) === 0 && (
          <MenuItem key="delete">Delete</MenuItem>
        )}
        {/* <MenuItem key="subscribe">Subscriber Info</MenuItem> */}
      </Menu>
    );
  };

  menu = (element, planKey) => {
    return (
      <Menu onClick={event => this.onSelect(element, planKey, event)}>
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        {this.displayEnrollments(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        {this.displayEnrollments(element) === 0 && (
          <MenuItem key="delete">Delete</MenuItem>
        )}
        {/* <MenuItem key="subscribe">Subscriber Info</MenuItem> */}
      </Menu>
    );
  };

  semesterMenu = (element, planKey) => {
    return (
      <Menu onClick={event => this.onSemesterSelect(element, planKey, event)}>
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        {this.displaySemesterEnrollments(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        {this.displaySemesterEnrollments(element) !== 0 && (
          <MenuItem key="view">View Details</MenuItem>
        )}
        {this.displayEnrollments(element) === 0 && (
          <MenuItem key="delete">Delete</MenuItem>
        )}
        {/* <MenuItem key="subscribe">Subscriber Info</MenuItem> */}
      </Menu>
    );
  };

  freeMenu = (element, planKey) => {
    return (
      <Menu onClick={event => this.onFreeSelect(element, planKey, event)}>
        <MenuItem key="inactive">
          {element.active === false ? "Make Active" : "Make Inactive"}
        </MenuItem>
        {this.displaySemesterEnrollments(element) === 0 && (
          <MenuItem key="edit">Edit</MenuItem>
        )}
        {this.displayEnrollments(element) === 0 && (
          <MenuItem key="delete">Delete</MenuItem>
        )}
      </Menu>
    );
  };

  deletePlan = async (plan, key) => {
    if (plan.prodId.id) {
      /**
       * Validation to check for number of enrollments before deleting
       */
      const findPlan = this.state.enrolledSubscribers.find(
        e => e.planId === plan.prodId.id
      );
      const applicationEnrollment = this.state.applicationEnrollment.filter(
        f => f.plan_id === plan.id
      ).length;
      const checkEnrollments = findPlan ? findPlan.data.length : 0;

      if (checkEnrollments > 0 || applicationEnrollment > 0) {
        toast.error("Cannot delete a program with enrollments/applications");
        return;
      } else {
        const ep = `${process.env.REACT_APP_API}/partners/stripe/delete_plan`;
        this.setGlobal({ loading: true });
        const res = await axios.delete(ep, {
          data: { planId: plan.prodId.id }
        });

        this.setGlobal({ loading: false });

        if (res.data.success) {
          this.setGlobal({
            program: {
              ...this.global.program,
              plans: this.global.program.plans.map((e, i) =>
                i === key ? { ...plan, archived: true } : e
              )
            }
          });
        }
        await this.dispatch.saveProgram();
        return;
      }
    }

    this.setGlobal({
      program: {
        ...this.global.program,
        plans: this.global.program.plans.map((e, i) =>
          i === key ? { ...plan, archived: true } : e
        )
      }
    });
    await this.dispatch.saveProgram();
  };

  handleUserDateSelectToggle = async (plan, key) => {
    this.setGlobal({
      program: {
        ...this.global.program,
        plans: this.global.program.plans.map((e, i) =>
          i === key
            ? { ...plan, isStartDateEnabled: !plan.isStartDateEnabled }
            : e
        )
      }
    });

    await this.dispatch.saveProgram();
  };

  handleUserClassDateSelectToggle = async (plan, key) => {
    this.setGlobal({
      program: {
        ...this.global.program,
        plans: this.global.program.plans.map((e, i) =>
          i === key
            ? { ...plan, isDateSelectionEnabled: !plan.isDateSelectionEnabled }
            : e
        )
      }
    });

    await this.dispatch.saveProgram();
  };

  handleUserSessionTimingSelectToggle = async (plan, key) => {
    this.setGlobal({
      program: {
        ...this.global.program,
        plans: this.global.program.plans.map((e, i) =>
          i === key ? { ...plan, isTimeEnabled: !plan.isTimeEnabled } : e
        )
      }
    });

    await this.dispatch.saveProgram();
  };

  async componentDidMount() {
    //...an array filled with values
    const plansIds = this.global.program.plans.filter(e => {
      const element = typeof e === "string" ? JSON.parse(e) : e;
      if (element.type === "Free") {
        return null; //filter() will remove these.
      }
      if (!element.prodId?.id) {
        return null; //filter() will remove these.
      } else {
        return element.prodId.id;
      }
    });

    plansIds.forEach(e => JSON.parse(e));

    const ep = `${process.env.REACT_APP_API}/partners/stripe/subscribers_plan`;

    const requestPlan = plansIds.map(e => {
      const element = typeof e === "string" ? JSON.parse(e) : e;
      return axios.get(ep, {
        params: {
          planId: element.prodId.id
        }
      });
    });

    axios
      .all(requestPlan)
      .then(responses => {
        const newData = responses.map(e => {
          return e.data.data
            ? { planId: e.config.params.planId, data: e.data.data.data }
            : { planId: e.config.params.planId, data: [] };
        });
        this.setState({
          enrolledSubscribers: newData
        });
        // use/access the results
      })
      .catch(errors => {});

    const ep2 = `${process.env.REACT_APP_API}/partners/programs/application/count/${this.global.program.id}`;
    const res2 = await axios.get(ep2);

    const ep3 = `${process.env.REACT_APP_API}/partners/programs/application-enrolled/count/${this.global.program.id}`;
    const res3 = await axios.get(ep3);

    if (res2.data.success) {
      this.setState({
        applicationEnrollment: res2.data.data,
        allEnrollments: res3.data.data
      });
    }
  }

  displayEnrollments = element => {
    /*
    const planId = element.prodId.id;

    if (planId) {
      const findPlan = this.state.enrolledSubscribers.find(
        e => e.planId == planId
      );
      return findPlan ? findPlan.data.length : 0;
    } else {
      return 0;
    }
    */
    const planId = element.id;

    if (planId || (planId === 0 && planId !== null)) {
      const findPlan = this.state.allEnrollments.filter(
        e => e.plan_id === planId && e.status_stage === "Accepted_Paid"
      );
      return findPlan ? findPlan.length : 0;
    } else {
      return 0;
    }
  };

  displaySemesterEnrollments = element => {
    const planId = element.id;

    if (planId || (planId === 0 && planId !== null)) {
      const findPlan = this.state.allEnrollments.filter(
        e => e.plan_id === planId && e.status_stage === "Accepted_Paid"
      );
      return findPlan ? findPlan.length : 0;
    } else {
      return 0;
    }
  };

  displaySingleSessionEnrollments = (element, planKey) => {
    console.log("element", element);
    console.log("planKey", planKey);
    return this.state.allEnrollments.filter(
      e => e.plan_id === planKey && e.status_stage === "Accepted_Paid").length;
  };

  displayEnableDate = (element, key) => {
    return (
      <Switch
        checked={element.isStartDateEnabled}
        color="primary"
        onChange={_ => this.handleUserDateSelectToggle(element, key)}
      />
    );
  };

  displayEnableClassDate = (element, key) => {
    return (
      <Switch
        checked={element.isDateSelectionEnabled}
        color="primary"
        onChange={_ => this.handleUserClassDateSelectToggle(element, key)}
      />
    );
  };

  displayEnableSessionsTimingDate = (element, key) => {
    return (
      <Switch
        checked={element.isTimeEnabled}
        color="primary"
        onChange={_ => this.handleUserSessionTimingSelectToggle(element, key)}
      />
    );
  };


  onDragEnd = (e) => {
    console.log("e in onDragEnd: ", e);
    if (!e.destination) {
      return;
    }

    const resultArr = Array.from(this.global.program?.plans);
    console.log("resultArr", resultArr);
    let newParsed = resultArr.map((f, id) => {
      if (typeof f === "string") {
        f = JSON.parse(f);
        if (!f.order) {
          f.order = id;
        }
        return f;
      } else {
        if (!f.order) {
          f.order = id;
        }
        return f;
      }
    });

    const [removedPlan] = newParsed.splice(e.source.index, 1);
    newParsed.splice(e.destination.index, 0, removedPlan);
    console.log("New Ordering: ", newParsed);
    const newOrdering = newParsed.map((plan, index) => ({...plan, order: index}));
    this.setGlobal({
      program: {
        ...this.global.program,
        plans: newOrdering
      }
    });
  }

  getDropdownMenu = (menu) => {
    return(
      <Dropdown
        trigger={["click"]}
        overlay={menu}
        animation="slide-up"
      >
        <button
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            minWidth: 20,
            minHeight: 20,
            outline: "none",
            border: 0,
            background: "#f4f6fd",
            fontSize: "14px"
          }}
        >
          <i className="fas fa-ellipsis-v"></i>
        </button>
      </Dropdown>
    );
  }

  render() {
    console.log("plans", this.global.program?.plans.sort((prev, next) => {
      let newPrev = prev;
      let newNext = next;
      if (typeof prev === "string") {
        newPrev = JSON.parse(prev);
      }

      if (typeof next === "string") {
        newNext = JSON.parse(next);
      }

      return newPrev.order - newNext.order;
    }));
    console.log("this.state", this.state);

    return (
      <>
        {this.state.openEditSingleSessionsPlanModal && (
          <EditSingleSessionPlanModal
            selectedItem={this.state.selectedItem}
            onClose={_ =>
              this.setState({ openEditSingleSessionsPlanModal: false })
            }
          />
        )}

        {this.state.openEditRecurringPlanModal && (
          <EditPricingPlanModal
            selectedItem={this.state.selectedItem}
            onClose={_ => this.setState({ openEditRecurringPlanModal: false })}
          />
        )}
        {this.state.openEditSemesterPlanModal && (
          <EditSemesterPlanModal
            selectedItem={this.state.selectedItem}
            onClose={_ => this.setState({ openEditSemesterPlanModal: false })}
            mode={"Edit"}
          />
        )}
        {this.state.openViewSemesterPlanModal && (
          <EditSemesterPlanModal
            selectedItem={this.state.selectedItem}
            onClose={_ => this.setState({ openViewSemesterPlanModal: false })}
            mode={"View"}
          />
        )}
        {this.state.openEditFreeModal && (
          <EditFreeModal
            selectedItem={this.state.selectedItem}
            onClose={_ => this.setState({ openEditFreeModal: false })}
          />
        )}
        {this.state.openEditFreeTrialModal && (
          <EditFreeTrialModal
            selectedItem={this.state.selectedItem}
            onClose={_=>this.setState({ openEditFreeTrialModal: false})}
            addChild={this.props.addChild}
          />
        )}
        {this.state.openEditDropInModal && (
          <EditDropInModal
            selectedItem={this.state.selectedItem}
            onClose={_=>this.setState({ openEditDropInModal: false })}
          />
        )}

        <div className="cont boxes-row mt-50px center-box">
          {/* <SectionFive vals={this.global.myApplicationProfileInfo} /> */}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" direction={`${window.innerWidth <= 767 ? "vertical" : "horizontal"}`}>
              {provided => (
                <div id="scroll-card-container" className="price-card-container" 
                  onWheel={(e) => {
                    e.preventDefault();
                    const container = document.getElementById("scroll-card-container");
                    container.scrollTo({
                      top: 0,
                      left: container.scrollLeft + e.deltaY,
                      behaviour: 'smooth'
                    });
                  }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {this.global.program?.plans
                    ?.sort((prev, next) => {
                      let newPrev = prev;
                      let newNext = next;
                      if (typeof prev === "string") {
                        newPrev = JSON.parse(prev);
                      }
                
                      if (typeof next === "string") {
                        newNext = JSON.parse(next);
                      }
                
                      return newPrev.order - newNext.order;
                    })
                    .map((e, key) => {
                      const element = typeof e === "string" ? JSON.parse(e) : e;
                      console.log("ELEMENT", key, ": ", element);
                      if (!element.type && !element.archived)
                        return (
                          <Draggable key={key} draggableId={String(key)} index={key}>
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                <PriceCardModal
                                  key={key}
                                  id={key}
                                  type={element.type}
                                  inactive={element.active === false}
                                  plan_category={"Recurring"}
                                  billing_cycle={element.billing_cycle}
                                  timing={element.timing}
                                  day_slot={element.day_slot}
                                  days_per_week={element.days_per_week}
                                  description={element.description}
                                  enrollments={this.displayEnrollments(element)}
                                  applications={this.state.applicationEnrollment.filter(
                                    f => f.plan_id === element.id
                                  ).length}
                                  dateSelection={this.displayEnableDate(element, key)}
                                  plan_price={element.tuition_rate}
                                  deposit={element.deposit}
                                  taxInfo={element.taxInfo}
                                  DropdownMenu={this.getDropdownMenu(this.menu(element, key))}
                                  onEditButtonClick={() => {
                                    this.setState({
                                      openEditRecurringPlanModal: true,
                                      selectedItem: element
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      if (element.type === "Semester" && !element.archived)
                        return (
                          <Draggable key={key} draggableId={String(key)} index={key}>
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                <PriceCardModal
                                key={key}
                                id={key}
                                type={element.type}
                                inactive={element.active === false}
                                description={element.description}
                                firstDay={getFirstDay(
                                    new Date(this.global.program.program_start),
                                    this.global.program.days_of_week
                                )}
                                lastDay={getLastDay(
                                    addWeeks(
                                      getFirstDay(
                                        new Date(this.global.program.program_start),
                                        this.global.program.days_of_week
                                      ),
                                      this.global.program.number_of_weeks - 1
                                    ),
                                    this.global.program.days_of_week
                                  )
                                }
                                plan_category={"Semester"}
                                enrollments={this.displaySemesterEnrollments(element)}
                                applications={this.state.applicationEnrollment.filter(
                                  f => f.plan_id === element.id
                                ).length}
                                plan_cap={element.planCap}
                                plan_price={element.total_price}
                                taxInfo={element.taxInfo}
                                installments={element.installments}
                                installments_breakdown={element.installments_breakdown}
                                DropdownMenu={this.getDropdownMenu(this.semesterMenu(element, key))}
                                addOn={element.addOn}
                                addOnItems={element.addOnItems}
                                onEditButtonClick={() => {
                                  this.setState({
                                    openEditSemesterPlanModal: true,
                                    selectedItem: element
                                  });
                                }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );

                      if (element.type === "Free Trial" && !element.archived)
                        return (
                          <Draggable key={key} draggableId={String(key)} index={key}>
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                <PriceCardModal
                                  key={key}
                                  id={key}
                                  type={element.type}
                                  inactive={element.active === false}
                                  plan_category={this.global.program.number_of_weeks == 0 ? "Recurring" : "Semester"}
                                  firstDay={getFirstDay(
                                    new Date(this.global.program.program_start),
                                    this.global.program.days_of_week
                                  )}
                                  lastDay={getLastDay(
                                    addWeeks(
                                      getFirstDay(
                                        new Date(this.global.program.program_start),
                                        this.global.program.days_of_week
                                      ),
                                      this.global.program.number_of_weeks - 1
                                    ),
                                    this.global.program.days_of_week
                                  )}
                                  description={element.description}
                                  plan_cap={element.planCap}
                                  separateForChildren={element.separateForChildren}
                                  autoEmail={element.allowAutoEmail}
                                  trialPeriod={element.trialPeriod}
                                  target={element.trialDemographic}
                                  enrollments={this.displaySemesterEnrollments(element)}
                                  applications={this.state.applicationEnrollment.filter(
                                    f => f.plan_id === element.id
                                  ).length}
                                  plan_price={element.total_price}
                                  DropdownMenu={this.getDropdownMenu(this.freeMenu(element, key))}
                                  onEditButtonClick={() => {
                                    this.setState({
                                      openEditFreeTrialModal: true,
                                      selectedItem: element
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );

                      if (element.type === "Drop-In" && !element.archived)
                        return (
                          <Draggable key={key} draggableId={String(key)} index={key}>
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                <PriceCardModal
                                  key={key}
                                  id={key}
                                  type={element.type}
                                  inactive={element.active === false}
                                  plan_category={element.type}
                                  firstDay={getFirstDay(
                                    new Date(this.global.program.program_start),
                                    this.global.program.days_of_week
                                  )}
                                  lastDay={getLastDay(
                                    addWeeks(
                                      getFirstDay(
                                        new Date(this.global.program.program_start),
                                        this.global.program.days_of_week
                                      ),
                                      this.global.program.number_of_weeks - 1
                                    ),
                                    this.global.program.days_of_week
                                  )}
                                  description={element.description}
                                  plan_cap={element.planCap}
                                  enrollments={this.displaySemesterEnrollments(element)}
                                  applications={this.state.applicationEnrollment.filter(
                                    f => f.plan_id === element.id
                                  ).length}
                                  plan_price={element.total_price}
                                  linkedSemesterPlan={element.linkedSemesterPlan}
                                  planPremium={element.premium}
                                  enableSpecificDates={element.enableSpecificDates}
                                  taxInfo={element.taxInfo}
                                  addOn={element.addOn}
                                  addOnItems={element.addOnItems}
                                  DropdownMenu={this.getDropdownMenu(this.freeMenu(element, key))}
                                  onEditButtonClick={() => {
                                    this.setState({
                                      openEditDropInModal: true,
                                      selectedItem: element
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      if (element.type === "Free" && !element.archived)
                        return (
                          <Draggable key={key} draggableId={String(key)} index={key}>
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                <PriceCardModal
                                  key={key}
                                  id={key}
                                  type={element.type}
                                  inactive={element.active === false}
                                  plan_category={this.global.program.number_of_weeks == 0 ? "Recurring" : "Semester"}
                                  firstDay={getFirstDay(
                                    new Date(this.global.program.program_start),
                                    this.global.program.days_of_week
                                  )}
                                  lastDay={getLastDay(
                                    addWeeks(
                                      getFirstDay(
                                        new Date(this.global.program.program_start),
                                        this.global.program.days_of_week
                                      ),
                                      this.global.program.number_of_weeks - 1
                                    ),
                                    this.global.program.days_of_week
                                  )}
                                  description={element.description}
                                  enrollments={this.displaySemesterEnrollments(element)}
                                  applications={this.state.applicationEnrollment.filter(
                                    f => f.plan_id === element.id
                                  ).length}
                                  plan_price={element.total_price}
                                  DropdownMenu={this.getDropdownMenu(this.freeMenu(element, key))}
                                  onEditButtonClick={() => {
                                    this.setState({
                                      openEditFreeModal: true,
                                      selectedItem: element
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );

                      if (element.type === "Single Sessions" && !element.archived)
                        return (  
                          <Draggable key={key} draggableId={String(key)} index={key}>
                            {provided => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style}}>
                                <PriceCardModal
                                  key={key}
                                  id={key}
                                  type={element.type}
                                  inactive={element.active === false}
                                  plan_category={`Single Session (${element.duration_type + (element.duration ? ` ${element.duration} min` : "")})`}
                                  description={element.description}
                                  frequency={element.frequency}
                                  quantity={element.quantity}
                                  enrollments={this.displaySingleSessionEnrollments(element, element.id)}
                                  applications={this.state.applicationEnrollment.filter(
                                    f => f.plan_id === element.id
                                  ).length}
                                  plan_price={element.total_price}
                                  taxInfo={element.taxInfo}
                                  dateSelection={
                                    element.duration_type === "Class" 
                                      ? this.displayEnableClassDate(element,key) 
                                      : this.displayEnableSessionsTimingDate(element, key)
                                  }
                                  DropdownMenu={this.getDropdownMenu(this.singleSessionsMenu(element, key))}
                                  onEditButtonClick={() => {
                                    this.setState({
                                      openEditSingleSessionsPlanModal: true,
                                      selectedItem: element
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        );

                      //this if-block is just a placeholder incase we want to offer an option in the future to revive an archived plan.
                      if (element.archived) {
                        return null;
                      }

                      //THIS POINT IN THE CODE SHOULD NEVER BE REACHED UNLESS YOU FORGOT
                      //TO ADD A PRICE PLAN THAT NONE OF THE ABOVE IF-BLOCKS FAILED TO CATCH!
                      return (
                        <div>
                          Something went wrong! Price plan "{element.type}" is not one
                          of the accepted types.
                        </div>
                      );
                    })}
                  {provided.placeholder}
                  {<button
                    id={`${this.global.program?.plans.filter((plan) => {
                      let element = plan;
                      if (typeof plan === 'string') {
                        element = JSON.parse(plan);
                      }
                      return !element.archived;
                    }).length === 0 ? "no-plans-button" : ""}`}
                    className="addchild add-plan"
                    onClick={_ => this.props.openModal()}
                  >
                    <h3>Add Plan</h3>
                  </button>}
                </div>
              )}
            </Droppable>
          </DragDropContext>

        </div>
      </>
    );
  }
}

export default Setup;
