import React from "reactn";
// import { background1, background2 } from "../../assets";
import axios from "axios";
import "../../assets/css/componentSpecificCss/programManage.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { NewTabbedTable } from "../../components/UI";

class ManagePrograms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: "",
      detailsModalShown: false,
      modalData: [],
      original: {},
      role: [],
      customers: {},
      enrolled: [],
      promotions: []
    };
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Manage Programs", to: "/programs/manage" }
      ]
    });
  }
  async componentDidMount() {
    const startTime = new Date().getTime();
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/programs/list_manage`;
    const results = await axios.get(ep);

    const role = await this.filterProgramsBasedUser();

    let data = results.data.data;

    if (results.data.success) {
      if (role?.programlist?.length > 0 && !role?.programlist?.includes(0)) {
        data = data.filter(
          e => role?.programlist?.filter(f => f === e.id).length > 0
        );
      } else if (
        role?.programlist === null ||
        role?.programlist?.length === 0
      ) {
        data = [];
      }

      const published = data
        .filter(item => item.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .sort((a, b) => a.display_order - b.display_order);
      const unpublished = data
        .filter(item => !item.published)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      const allPrograms = published.concat(unpublished);

      this.setState({
        data: allPrograms
      });
    }

    const ep2 = `${process.env.REACT_APP_API}/partners/promotions/all`;
    const results2 = await axios.get(ep2);

    if (results2.data.success) {
      if (results2.data.data.length !== 0) {
        const programPromotions = results2.data.data.filter(promotion => {
          return promotion.promotion_type === "program";
        });
        this.setState({
          promotions: programPromotions
        });
      }
    }

    this.setGlobal({ loading: false });
    console.log("Time Taken", new Date().getTime() - startTime);
  }

  hasPromotion = programId => {
    for (const promotion of this.state.promotions) {
      if (promotion.programId === programId) {
        return true;
      }
    }
    return false;
  };

  filterProgramsBasedUser = async () => {
    if (this.global?.dsUser?.sub_user && this.global?.dsUser?.role) {
      const ep = `${process.env.REACT_APP_API}/partners/users/all-details/${this.global?.dsUser?.user_id}`;
      const results = await axios.get(ep);
      console.log("results", results);
      if (results.data.success) {
        this.setState({
          role: results.data.data
        });
        return results.data.data;
      }
    }
    return {};
  };

  viewDetail = async row => {
    this.setState({ detailsModalShown: true, modalData: row });
  };
  viewComments = async row => {
    this.setState({ commentsModalShown: true, modalData: row });
  };
  daysInSystem = (r, i) => {
    return (
      <td key={i}>
        {parseInt((Date.now() - new Date(r.insys).getTime()) / (3600000 * 24))}
      </td>
    );
  };

  render() {
    return (
      <div className="admin">
        <div
          className="container-fluid adminprofiles"
          style={{ height: "70%" }}
        >
          <div className="cont">
            <h1>Programs</h1>
          </div>
          <div className="cont">
            {this.state.data.length > 0 ? (
              <div className="tablecont">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.data.map((e, key) => (
                    <div
                      className="program-cards"
                      style={{
                        backgroundColor: "white",
                        width: "250px",
                        borderRadius: "10px",
                        marginRight: "20px",
                        marginBottom: "20px",
                        cursor: "pointer"
                      }}
                      key={key}
                      onClick={_ =>
                        this.props.history.push(
                          `/partners/programs/manage/single/${e.id}`
                        )
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "200px"
                        }}
                      >
                        <LazyLoadImage
                          src={`${process.env.REACT_APP_DS_FILES_S3}/${e.program_photo}`}
                          alt="Program"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            objectFit: "cover"
                          }}
                          wrapperProps={{
                            style: {
                              width: "100%",
                              height: "100%",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              objectFit: "cover"
                            }
                          }}
                          effect="opacity"
                        />
                      </div>
                      {this.hasPromotion(e.id) ? (
                        <p
                          style={{
                            height: "30px",
                            textAlign: "center",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "12px",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                        >
                          {e.program_name} <em>{"(Promotion)"}</em>
                        </p>
                      ) : (
                        <p
                          style={{
                            height: "30px",
                            textAlign: "center",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "12px",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                        >
                          {e.program_name}
                        </p>
                      )}
                      <p
                        style={{
                          textAlign: "center",
                          color: "grey",
                          fontSize: "10px",
                          marginTop: "5px",
                          marginBottom: "5px"
                        }}
                      >
                        {e.program_category}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px"
                        }}
                      >
                        <span
                          style={{
                            flexGrow: 1,
                            color: "darkgrey",
                            fontSize: "12px",
                            marginLeft: "10px"
                          }}
                        >
                          {/*  {key + 1} / {this.state.data.length} */}
                          {e.enrolledNum / e.total_spots >= 1 ? (
                            <strong
                              style={{
                                flexGrow: 1,
                                color: "#1BC889",
                                fontSize: "12px",
                                marginLeft: "0px"
                              }}
                            >
                              Full
                            </strong>
                          ) : (
                            `${e.enrolledNum} enrolled ${""} / ${""} ${
                              e.total_spots ? e.total_spots : "0"
                            } spots`
                          )}
                        </span>
                        <img
                          src="/static/media/monthlysurvey.122ae06e.svg"
                          alt=""
                          style={{ marginRight: "10px" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "60vh",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <p
                  style={{
                    fontStyle: "italic",
                    textAlign: "center",
                    fontSize: "18px"
                  }}
                >
                  Click{" "}
                  <b>
                    <Link to="/partners/programs/list">here</Link>
                  </b>{" "}
                  to start creating your own programs!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ManagePrograms;
